
import { AppInfo } from "@capacitor/app";
import { DeviceInfo } from "@capacitor/device";
import { Component, VModel, Vue } from "vue-property-decorator";

import ListItem from "./NavigationDrawerListItem.vue";

@Component({
  components: {
    ListItem
  }
})
export default class NavigationDrawerComponent extends Vue {
  @VModel({ type: Boolean }) navDrawer!: boolean;

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get appInfo(): AppInfo {
    return this.$store.getters["app/appInfo"];
  }

  get deviceInfo(): DeviceInfo {
    return this.$store.getters["device/deviceInfo"];
  }

  get appMode() {
    const mode = this.$store.getters["app/mode"];
    let url = process.env.VUE_APP_CORE_URL;
    let endpointChar = "P";

    if (mode == "Dev") url = process.env.VUE_APP_CORE_DEV_URL;
    if (mode == "Test") url = process.env.VUE_APP_CORE_TEST_URL;

    if (url?.includes("dev")) endpointChar = "D";
    if (url?.includes("test")) endpointChar = "T";

    return `${endpointChar}.2.0`;
  }
}
