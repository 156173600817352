
import { Component, Prop, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

@Component
export default class NavigationDrawerListItemComponent extends Vue {
  @Prop({ type: String, required: true }) readonly icon!: string;
  @Prop({ type: Object, required: true }) readonly to!: Route;
  @Prop({ type: String, required: true }) readonly title!: string;
}
